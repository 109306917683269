import { Button, message } from 'antd';
import React, { FC } from 'react';

import { useSaveReupDraftOrdersMutation } from '@/graphql/hooks';
import {
  License,
  ReupOrderInput,
  ReupPurchaseOrder,
  SaveReupDraftOrdersMutation,
} from '@/graphql/types';
import {
  HandelOnDraftOrdersCreatedCallback,
  HandleCheckedChangedCallback,
  HandleReorderDataCallback,
  Reorder,
} from '@/types';

type AddToPOButtonProps = {
  orgkey: string;
  storeKey: string;
  license: License;
  reorders: Reorder[];
  onReorderDataUpdated: HandleReorderDataCallback;
  onReordersCheckedUpdated: HandleCheckedChangedCallback;
  onDraftOrdersCreated: HandelOnDraftOrdersCreatedCallback;
  reordersChecked: React.Key[];
  disabled: boolean;
};
const updateLeftOverReorderQty = (
  allOrders: Reorder[],
  leftOverOrders: Reorder[]
) => {
  return allOrders.map((o) => {
    const leftOver = leftOverOrders.find((lo) => lo.key === o.key);
    if (leftOver) {
      o.key += '-LEFTOVER';
      o.quantityOrdered = o.quantityOrdered
        ? (o.quantityOrdered += leftOver.quantity)
        : leftOver.quantity;
      o.quantity =
        leftOver.required -
        (leftOver.quantityOnHand ?? 0) -
        (leftOver.quantityOrdered ?? 0);
      o.suggested = o.quantity;
    }

    return o;
  });
};

const AddToPOButton: FC<AddToPOButtonProps> = ({
  orgkey,
  storeKey,
  license,
  reorders,
  onReorderDataUpdated,
  onDraftOrdersCreated,
  reordersChecked,
  onReordersCheckedUpdated,
  disabled,
}) => {
  const notifyMovedToDraftOrder = () => {
    const url = `/${orgkey}/purchase-orders/drafts?store=${storeKey}`;
    void message.success({
      content: (
        <div>
          Added items to drafts! <a href={url}>See them now.</a>
        </div>
      ),
    });
  };
  const { mutate, isLoading } = useSaveReupDraftOrdersMutation();

  const moveToDraftOrder = () => {
    let updatedReorders = [...reorders];
    const orderIdsToRemove = [] as string[];
    const updatedPurchaseOrderLines = [];
    const leftOverOrders: Reorder[] = [];

    for (const [, element] of updatedReorders.entries()) {
      if (element.key && reordersChecked.includes(element.key)) {
        if (element.quantity >= element.suggested) {
          orderIdsToRemove.push(element.key);
        } else {
          leftOverOrders.push(element);
        }

        updatedPurchaseOrderLines.push(element);
      }
    }

    updatedReorders = updatedReorders.filter(
      (uo) => !orderIdsToRemove.includes(uo.key as string)
    );

    if (updatedPurchaseOrderLines.length > 0) {
      const reupOrderItems = updatedPurchaseOrderLines.map((li) => {
        const lineItem: ReupOrderInput = {
          buyerId: license.id, // ToDo: Get from tenant hard coded for now
          supplierId: li.supplierId,
          supplierName: li.supplier,
          cost: li.unitPrice ? li.quantity * li.unitPrice : 0,
          productId: li.key ?? '',
          productName: li.productName ?? '',
          quantity: li.quantity,
          required: li.required,
          suggested: li.suggested,
          unitPrice: li.cost ?? 0,
          orgKey: orgkey,
        };

        return lineItem;
      });

      void mutate(
        { input: reupOrderItems },
        {
          onSuccess: (data: SaveReupDraftOrdersMutation) => {
            notifyMovedToDraftOrder();
            const returnOrders =
              leftOverOrders.length > 0
                ? updateLeftOverReorderQty(updatedReorders, leftOverOrders)
                : [...updatedReorders];
            onReorderDataUpdated(returnOrders);
            onReordersCheckedUpdated([]);
            onDraftOrdersCreated(data.purchaseOrders as ReupPurchaseOrder[]);
          },
          onError: () => {
            void message.error(
              'Unable to save draft orders. Please refresh the page and try again. Reach out to chat support if problems continue.'
            );
          },
        }
      );
    }
  };

  return (
    <Button
      disabled={disabled}
      onClick={() => moveToDraftOrder()}
      loading={isLoading}
      type="primary"
    >
      Add to Draft Orders
    </Button>
  );
};
export default AddToPOButton;
