import { Select } from 'antd';
import { FC } from 'react';

const { Option } = Select;

type SamplePeriodProps = {
  samplePeriod: string;
  onSamplePeriodUpdated: (value: string) => void;
  disabled?: boolean;
};
const SamplePeriod: FC<SamplePeriodProps> = ({
  samplePeriod,
  onSamplePeriodUpdated,
  disabled,
}) => {
  return (
    <div>
      <div>
        <span>Sample Period</span>
      </div>
      <Select
        showSearch
        className="w-full"
        placeholder="Select Period"
        optionFilterProp="children"
        defaultValue={samplePeriod}
        onChange={onSamplePeriodUpdated}
        disabled={disabled}
      >
        <Option value="w1">1 week</Option>
        <Option value="w2">2 weeks</Option>
        <Option value="w12">12 weeks</Option>
      </Select>
    </div>
  );
};

export default SamplePeriod;
