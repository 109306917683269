import { SyncOutlined } from '@ant-design/icons';
import { useUser, withPageAuthRequired } from '@auth0/nextjs-auth0';
import { Button, Col, message, Row, Skeleton, Space, Spin } from 'antd';
import { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import { generateEventData, identify } from '@/analytics';
import {
  DraftOrderCreated,
  draftOrderCreated,
  ReorderAssistantViewed,
  reorderAssistantViewed,
} from '@/analytics/segment';
import formatCurrency from '@/components/common/Extensions/CurrencyExtensions';
import ReorderListPage from '@/components/reorders';
import AddToPOButton from '@/components/reorders/addToPoButton';
import AutoCompleteProduct from '@/components/reorders/autoCompleteProduct';
import EditButton from '@/components/reorders/editButton';
import PageTitle from '@/components/reorders/pageTitle';
import ReplenishmentStrategy from '@/components/reorders/replenishmentStrategy';
import SamplePeriod from '@/components/reorders/samplePeriod';
import SuggestFor from '@/components/reorders/suggestFor';
import {
  useGetLicensesQuery,
  useGetReupProductsQuery,
  useGetReupPurchaseOrdersQuery,
} from '@/graphql/hooks';
import {
  License,
  LicenseFilter,
  PurchaseOrderStatus,
  Reup_Product,
  ReupPurchaseOrder,
  ReupPurchaseOrderFilter,
} from '@/graphql/types';

import { Reorder, ReorderAssistantState } from '../../types';

const stockoutBasedOnRangeSelected = (
  product: Reup_Product,
  samplePeriod: string
) => {
  if (samplePeriod === 'w1')
    return product.EstimatedStockOut_1Week?.replace(/-/g, '/');
  if (samplePeriod === 'w2')
    return product.EstimatedStockOut_2Weeks?.replace(/-/g, '/');
  if (samplePeriod === 'w12')
    return product.EstimatedStockOut_12Weeks?.replace(/-/g, '/');
};

const velocityBasedOnRangeSelected = (
  product: Reup_Product,
  samplePeriod: string
): number => {
  if (samplePeriod === 'w1')
    return product.Velocity_1Week ? product.Velocity_1Week / 7 : 0;
  if (samplePeriod === 'w2')
    return product.Velocity_2Weeks ? product.Velocity_2Weeks / 7 : 0;
  if (samplePeriod === 'w12')
    return product.Velocity_12Weeks ? product.Velocity_12Weeks / 7 : 0;
  return 0;
};
const calculateRequiredQuantity = (
  quantitySoldPerDay: number,
  quantityOnHand: number,
  quantityOnOrder: number
) => {
  quantityOnOrder = 0; // ToDo: pass in actual qty on order from Tenant
  const daysNeeded = 14; // MVP - 2 weeks hard coded;
  const leadTime = 7; // MVP - days hardcoded
  const buffer = 1.1; // MVP - 10%
  const exactNeeded = (daysNeeded + leadTime) * quantitySoldPerDay;
  const withBuffer = exactNeeded * buffer;
  const suggestedQuantity = withBuffer - quantityOnHand - quantityOnOrder;

  return suggestedQuantity > 0 ? Math.ceil(suggestedQuantity) : 0;
};

export const reorderFromProduct = (
  product: Reup_Product,
  samplePeriod: string,
  onOrder: number
) => {
  const stockOutDate = new Date(
    stockoutBasedOnRangeSelected(product, samplePeriod) as string
  );

  const quantityOrdered = onOrder; // TODO: Query orders placed but not delivered via ReUp to populate this value
  const dailyVelocity = velocityBasedOnRangeSelected(product, samplePeriod);
  const estimatedDailyLoss = product.UnitPrice
    ? product.UnitPrice * dailyVelocity
    : 0;

  const required = calculateRequiredQuantity(
    dailyVelocity,
    product?.QuantityOnHand ? product?.QuantityOnHand : 0,
    0 // ToDo: Pass in on order from Tenant
  );

  const suggested = Math.ceil(
    required - ((product.QuantityOnHand as number) + quantityOrdered)
  );

  const r: Reorder = {
    key: product.key,
    productName: product.ProductName,
    category: product.CategoryType,
    supplier:
      product && product.Suppliers
        ? product.Suppliers[0].SupplierName
        : '(none)',
    supplierId:
      product && product.Suppliers ? product.Suppliers[0].SupplierId : '(none)',
    cost: product && product.Suppliers ? product.Suppliers[0].UnitCost || 0 : 0,
    quantityOnHand: product.QuantityOnHand as number,
    quantityOrdered,
    estimatedDailyLoss,
    suggested,
    required,
    stockOutDate,
    quantity: suggested > 0 ? suggested : 0,
    unitPrice: product.UnitPrice as number,
    dailyVelocity,
  };

  return r;
};

// Set the On Order for each product with a matching PO line item.
const setOnOder = (
  pos: ReupPurchaseOrder[],
  products: Reup_Product[],
  samplePeriod: string,
  maxSuggestedReorders?: number
): Reorder[] => {
  if (pos && products) {
    const re = products?.slice(0, maxSuggestedReorders ?? 100).map((p) => {
      const po = pos
        .filter(
          (fpo) =>
            fpo.status === PurchaseOrderStatus.Draft ||
            fpo.status === PurchaseOrderStatus.Placed
        )
        .find((r) =>
          r.reupLineItems.nodes
            .filter((li) => !li?.isDeleted)
            .map((rli) => rli?.productId)
            .includes(p.key)
        );

      let onOder = 0;
      if (po) {
        const lineItem = po?.reupLineItems?.nodes.find(
          (pli) => pli?.productId === p.key
        );

        onOder = lineItem?.requestedQuantity ?? 0;
      }

      return reorderFromProduct(p, samplePeriod, onOder);
    });
    return re.filter((pr) => pr.quantity > 0);
  }

  return [];
};

const ReorderAssistantPage: NextPage = () => {
  const [reorderAssistantState, setReorderAssistantState] = useState(
    ReorderAssistantState.Suggest
  );
  const [reordersChecked, setReordersChecked] = useState([] as React.Key[]);
  const [samplePeriod, setSamplePeriod] = useState('w12');
  const [suggestForPeriod, setSuggestForPeriod] = useState('w2');
  const [reorders, setReorders] = useState([] as Reorder[]);
  const [unFilteredOrders, setUnfilteredOrders] = useState([] as Reorder[]);
  const [reupProducts, setReupProducts] = useState([] as Reup_Product[]);
  const [reupPurchaseOrders, setReupPurchaseOrders] = useState(
    [] as ReupPurchaseOrder[]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [license, setLicense] = useState({} as License);
  const [trackingComplete, setTrackingComplete] = useState(false);

  const { user } = useUser();
  const {
    query: { org, store, pathName },
  } = useRouter();
  const orgkey = org as string;
  const storekey = store as string;

  const { data, isFetched, isFetching, error } = useGetReupProductsQuery({
    orgkey,
    storekey,
    limit: 100,
  });

  const licenseFilter: LicenseFilter = {
    licenseNumber: {
      equalTo:
        reupProducts.length > 0 && reupProducts[0].LicenseNum
          ? reupProducts[0].LicenseNum
          : '',
    },
  };

  const {
    data: licenseData,
    isFetched: licensesFetched,
    isFetching: licensesIsFeting,
  } = useGetLicensesQuery(
    { filter: licenseFilter },
    {
      enabled: reupProducts.length > 0,
    }
  );

  const poFilter: ReupPurchaseOrderFilter = {
    buyerId: {
      equalTo: license.id,
    },
    isDeleted: { equalTo: false },
    organizationKey: {
      equalTo: orgkey,
    },
  };

  const { data: poData, isFetched: poFetched } = useGetReupPurchaseOrdersQuery(
    {
      poFilter,
    },
    { enabled: license.id > 0 && reupProducts.length > 0 }
  );

  const maxSuggestedReorders = 100;

  const getSelectedOrdersTotal = () => {
    const selectedOrders = reorders.filter((o) =>
      reordersChecked.includes(o.key as React.Key)
    );

    const sumSelectedOrders = selectedOrders.reduce(
      (p, c) => p + c.quantity * c.cost,
      0
    );

    return formatCurrency(sumSelectedOrders);
  };

  useEffect(() => {
    if (
      licensesFetched &&
      licenseData?.licenses &&
      licenseData?.licenses?.nodes?.length > 0
    ) {
      setLicense(licenseData?.licenses?.nodes[0] as License);
    }

    if (poFetched) {
      setIsLoading(false);

      if (poData?.purchaseOrders?.nodes) {
        setReupPurchaseOrders(
          poData?.purchaseOrders?.nodes as ReupPurchaseOrder[]
        );

        const setOnHandForReorders = setOnOder(
          reupPurchaseOrders,
          reupProducts,
          samplePeriod,
          maxSuggestedReorders
        );
        setReorders(setOnHandForReorders);
        setUnfilteredOrders(setOnHandForReorders);
      }
    }
  }, [
    licensesFetched,
    licenseData?.licenses,
    license,
    poFetched,
    poData?.purchaseOrders?.nodes,
    reupProducts,
    reupPurchaseOrders,
    samplePeriod,
  ]);

  useEffect(() => {
    if (isFetched && data?.products) {
      setReupProducts(data?.products);
    }

    if (error)
      void message.error(
        `There was an issue loading the product data for ${orgkey} - ${storekey}`
      );
  }, [
    data?.products,
    error,
    isFetched,
    orgkey,
    reupProducts,
    samplePeriod,
    storekey,
  ]);

  // Segment Reorder Assistant Viewed
  const reorderAssistantViewedEvent = useCallback(() => {
    reorderAssistantViewed(
      generateEventData(
        orgkey,
        storekey,
        license.licenseNumber,
        license.state,
        {
          email: user?.email,
        }
      ) as ReorderAssistantViewed
    );

    identify({
      CurrentAccountLink: pathName as string,
      license: {
        licenseNumber: license.licenseNumber,
        licenseName: license.licenseName,
      },
      Email: user?.email as string,
      Name: user?.name as string,
      Product: 'Reup',
    });
  }, [
    orgkey,
    storekey,
    license.licenseNumber,
    license.state,
    license.licenseName,
    user?.email,
    user?.name,
    pathName,
  ]);

  useEffect(() => {
    if (licensesFetched && license?.licenseNumber && !trackingComplete) {
      reorderAssistantViewedEvent();
      setTrackingComplete(true);
    }
  }, [
    reorderAssistantViewedEvent,
    licensesFetched,
    trackingComplete,
    license?.licenseNumber,
  ]);

  // ToDo: A discussion with Rufus we agreed this may not be used anymore.
  // There seems to be no reason to add products to a reorder to then add them to
  // a draft order. Products can be added via the draft orders page.
  const onAutoCompleteSelect = (autoProductName: string) => {
    const product = reupProducts.find(
      (p) => p?.key === autoProductName
    ) as Reup_Product;
    if (product) {
      const updatedReorders = [...reorders];
      const autoCompleteReorder = reorderFromProduct(product, samplePeriod, 0); // ToDo: May need to pass in PO data.
      autoCompleteReorder.quantity = 0; // We might currently have multiple instances of the same product in the reorder list. Should we suppress this?
      updatedReorders.push(autoCompleteReorder);
      setReorders(updatedReorders);
      void message.success(
        'The product has been added to the Reorder Assistant'
      );
    }
  };

  const onReorderDataUpdated = (d: Reorder[]) => {
    setReorders(d);

    // TODO: This is a hack so the ANTD table will refresh. We are using the `key` column
    // as the product Id from BigQuery. Since this does not change ANTD does not know to refresh the data.
    // the `-LEFTOVER` key is removed here and added in the addToPoButton.tsx.
    // In this situation the user has a quantity less than the suggested so we want to
    // keep that item in the Reorder list, but reset is quantity.
    const hasLeftOvers = d.some((o) => o.key?.includes('-LEFTOVER'));

    if (hasLeftOvers) {
      setReorders(
        d.map((o) => {
          if (o.key?.includes('-LEFTOVER')) {
            o.key = o.key.replace('-LEFTOVER', '');
          }

          return o;
        })
      );
    }
  };

  const onDraftOrdersCreated = (d?: ReupPurchaseOrder[]) => {
    if (d && d.length > 0) {
      for (const order of d) {
        draftOrderCreated(
          generateEventData(
            orgkey,
            storekey,
            license.licenseNumber,
            license.state,
            {
              email: user?.email,
              draftOrder: order,
            }
          ) as DraftOrderCreated
        );
      }
    }
  };

  const onSamplePeriodUpdated = (d: string) => {
    setSamplePeriod(d);
  };

  const onSuggestForPeriodUpdated = (d: string) => {
    setSuggestForPeriod(d);
  };

  const onReorderAssistantStateUpdated = (d: ReorderAssistantState) => {
    setReorderAssistantState(d);
  };

  const onReordersCheckedUpdated = (d: React.Key[]) => {
    setReordersChecked(d);
  };

  const onSearch = (d: string) => {
    const searchOrders = unFilteredOrders.filter((r) =>
      r.productName?.toLocaleLowerCase().includes(d.toLocaleLowerCase())
    );

    setReorders(searchOrders);
  };

  return (
    <div>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <div className="p-6 bg-white mb-6 border border-solid border-gray-300">
        <Row justify="space-between">
          <Col>
            <PageTitle />
          </Col>
          <Col flex="auto" className="flex justify-end">
            {isFetched && reupProducts.length > 0 && (
              <AutoCompleteProduct
                products={reupProducts}
                reorderAssistantState={reorderAssistantState}
                onAutoCompleteSelect={onAutoCompleteSelect}
                onSearch={onSearch}
              />
            )}
            {isFetching && !isFetched && <Spin />}
          </Col>
        </Row>
        <Row justify="space-between" className="flex flex-row h-full items-end">
          <Col flex="auto">
            <Space align="end">
              <ReplenishmentStrategy />

              <SamplePeriod
                samplePeriod={samplePeriod}
                onSamplePeriodUpdated={onSamplePeriodUpdated}
                disabled={
                  reorderAssistantState !== ReorderAssistantState.Suggest
                }
              />

              <SuggestFor
                suggestForPeriod={suggestForPeriod}
                onSuggestForPeriodUpdated={onSuggestForPeriodUpdated}
              />

              {/* TO DO wire up sync */}
              <Button icon={<SyncOutlined />} disabled>
                Run
              </Button>

              <EditButton
                reorderAssistantState={reorderAssistantState}
                onReorderAssistantStateUpdated={onReorderAssistantStateUpdated}
                onReordersCheckedUpdated={onReordersCheckedUpdated}
                disabled={licensesIsFeting}
              />
            </Space>
          </Col>
          <Col>
            <Space align="baseline">
              <h5 className="m-0 p-0">
                <b>{reordersChecked.length}</b> item
                {reordersChecked.length === 0
                  ? 's'
                  : reordersChecked.length > 1
                  ? 's'
                  : ''}{' '}
                selected | {getSelectedOrdersTotal()}
              </h5>

              <AddToPOButton
                orgkey={orgkey}
                storeKey={storekey}
                license={license}
                reorders={reorders}
                onReorderDataUpdated={onReorderDataUpdated}
                onReordersCheckedUpdated={onReordersCheckedUpdated}
                onDraftOrdersCreated={onDraftOrdersCreated}
                reordersChecked={[...reordersChecked]}
                disabled={
                  reorderAssistantState !== ReorderAssistantState.Order ||
                  reordersChecked.length <= 0
                }
              />
            </Space>
          </Col>
        </Row>
      </div>

      <div className="p-6 bg-white mb-6 border border-solid border-gray-300">
        {!isLoading && reupProducts.length > 0 && (
          <ReorderListPage
            reorders={[...reorders]}
            onReorderDataUpdated={onReorderDataUpdated}
            onReordersCheckedUpdated={onReordersCheckedUpdated}
            reorderAssistantState={reorderAssistantState}
            selectedRows={reordersChecked}
          />
        )}
        {isLoading && <Skeleton active />}
      </div>
    </div>
  );
};

export default withPageAuthRequired(ReorderAssistantPage);
