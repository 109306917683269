import { Tooltip } from 'antd';
import { FC } from 'react';

const PageTitle: FC = () => {
  const renderTooltip = () => {
    return (
      <>
        <p>
          The Suggested Quantity to reorder includes a turnaround time of 7 days
          plus a safety buffer of 10%.
        </p>
        <br />
        <p>Let us know if you’d like to customize these values!</p>
      </>
    );
  };

  return (
    <div className="mb-11">
      <Tooltip title={renderTooltip}>
        <h2 className="text-blue-700 w-fit">Reorder Assistant</h2>
      </Tooltip>
      Build purchase orders based on our suggestions
    </div>
  );
};
export default PageTitle;
