import React, { FC } from 'react';

import {
  HandleCheckedChangedCallback,
  HandleReorderDataCallback,
  Reorder,
  ReorderAssistantState,
} from '../../types';
import ReordersTable from './table';

type ReorderListPageProps = {
  reorders: Reorder[];
  onReorderDataUpdated: HandleReorderDataCallback;
  onReordersCheckedUpdated: HandleCheckedChangedCallback;
  reorderAssistantState: ReorderAssistantState;
  selectedRows: React.Key[];
};

const ReorderListPage: FC<ReorderListPageProps> = ({
  reorders,
  onReorderDataUpdated,
  onReordersCheckedUpdated,
  reorderAssistantState,
  selectedRows,
}) => {
  return (
    <div>
      <ReordersTable
        reorders={reorders}
        onReorderDataUpdated={onReorderDataUpdated}
        reorderAssistantState={reorderAssistantState}
        onReordersCheckedUpdated={onReordersCheckedUpdated}
        selectedRows={selectedRows}
      />
    </div>
  );
};

export default ReorderListPage;
