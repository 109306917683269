import { Select } from 'antd';
import { FC } from 'react';

const { Option } = Select;

type SuggestForProps = {
  suggestForPeriod: string;
  onSuggestForPeriodUpdated: (value: string) => void;
};
const SuggestFor: FC<SuggestForProps> = ({
  suggestForPeriod,
  onSuggestForPeriodUpdated,
}) => {
  return (
    <div>
      <div>
        <span>Suggest For</span>
      </div>
      <Select
        disabled
        showSearch
        className="w-full"
        placeholder="2 Weeks"
        optionFilterProp="children"
        defaultValue={suggestForPeriod}
        onChange={onSuggestForPeriodUpdated}
      >
        <Option value="w2">2 weeks</Option>
      </Select>
    </div>
  );
};

export default SuggestFor;
