import { InputNumber, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { format } from 'date-fns';
import React, { FC } from 'react';

import {
  HandleCheckedChangedCallback,
  HandleReorderDataCallback,
  Reorder,
  ReorderAssistantState,
} from '@/types';

import formatCurrency from '../common/Extensions/CurrencyExtensions';

type ReordersTableProps = {
  reorders: Reorder[];
  onReorderDataUpdated: HandleReorderDataCallback;
  reorderAssistantState: ReorderAssistantState;
  onReordersCheckedUpdated: HandleCheckedChangedCallback;
  selectedRows: React.Key[];
};

const ReordersTable: FC<ReordersTableProps> = ({
  reorders,
  onReorderDataUpdated,
  reorderAssistantState,
  onReordersCheckedUpdated,
  selectedRows,
}) => {
  const onQuantityChange = (index: number) => (value: number) => {
    const newData = [...reorders];
    newData[index].quantity = Number(value);
    onReorderDataUpdated(newData);
  };

  const allColumns: ColumnType<Reorder>[] = [
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'ProductName',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) => {
        return a.productName && b.productName
          ? a.productName.localeCompare(b.productName)
          : 0;
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) => a.category.localeCompare(b.category),
      filters: reorders
        .map((r) => r.category)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((c) => ({ text: c, value: c })),
      onFilter: (value: string | number | boolean, record: Reorder) =>
        record.category.indexOf(value as string) === 0,
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) => a.supplier.localeCompare(b.supplier),
      filters: reorders
        .map((r) => r.supplier)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((c) => ({ text: c, value: c })),
      onFilter: (value: string | number | boolean, record: Reorder) =>
        record.supplier.indexOf(value as string) === 0,
    },
    {
      title: 'Required',
      dataIndex: 'required',
      key: 'required',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) => a.required - b.required,
    },
    {
      title: 'On Hand',
      dataIndex: 'quantityOnHand',
      key: 'quantityOnHand',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) =>
        (a.quantityOnHand as number) - (b.quantityOnHand as number),
    },
    {
      title: 'On Order',
      dataIndex: 'quantityOrdered',
      key: 'quantityOrdered',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) =>
        (a.quantityOrdered as number) - (b.quantityOrdered as number),
    },
    {
      title: 'Est. Sellout',
      dataIndex: 'stockOutDate',
      key: 'stockOutDate',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) =>
        a.stockOutDate.getTime() - b.stockOutDate.getTime(),
      render: (value: Date, record: Reorder) => {
        if (record.quantityOnHand === 0) {
          return 'Sold Out';
        }
        return format(value, 'iii, MMM d');
      },
    },
    {
      title: 'Estimated Loss/day',
      key: 'estimatedDailyLoss',
      dataIndex: 'estimatedDailyLoss',
      render: (amt: number) => <span>{formatCurrency(amt)}</span>,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) =>
        a.estimatedDailyLoss && b.estimatedDailyLoss
          ? a.estimatedDailyLoss - b.estimatedDailyLoss
          : 0,
    },
    // ToDo: MVP - Keeping these here if we need to debug later
    // {
    //   title: 'REMOVE Unit Price',
    //   key: 'unitPrice',
    //   dataIndex: 'unitPrice',
    //   render: (amt: number) => <span>{formatCurrency(amt)}</span>,
    // },
    // {
    //   title: 'REMOVE Daily Velocity',
    //   key: 'dailyVelocity',
    //   dataIndex: 'dailyVelocity',
    // },
    {
      title: 'Cost',
      key: 'cost',
      dataIndex: 'cost',
      // https://stackoverflow.com/questions/59805011/antd-table-disable-unsorted-option-for-a-column-force-sort
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amt: number) => <span>{formatCurrency(amt)}</span>,
      sorter: (a: Reorder, b: Reorder) => a.cost - b.cost,
    },
    {
      title:
        reorderAssistantState === ReorderAssistantState.Order
          ? 'Quantity'
          : 'Suggested',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (qty: number, record: Reorder, index: number) =>
        reorderAssistantState === ReorderAssistantState.Order ? (
          <InputNumber defaultValue={qty} onChange={onQuantityChange(index)} />
        ) : (
          <span>{qty}</span>
        ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) => a.quantity - b.quantity,
    },
    {
      title: 'Total',
      key: 'total',
      render: (e: Reorder) => (
        <span>{formatCurrency(e.quantity * e.cost)}</span>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a: Reorder, b: Reorder) =>
        a.quantity * a.cost - b.quantity * b.cost,
    },
  ];

  const columns = allColumns.filter(
    (col) =>
      reorderAssistantState === ReorderAssistantState.Order ||
      col.dataIndex !== 'Total'
  );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      onReordersCheckedUpdated(selectedRowKeys);
    },
    getCheckboxProps: (record: Reorder) => ({
      name: record.productName,
      disabled: reorderAssistantState === ReorderAssistantState.Suggest,
    }),
    selectedRowKeys: selectedRows,
  };

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns}
      dataSource={[...reorders]}
      pagination={{
        position: ['bottomLeft'],
        size: 'default',
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
      }}
      className="reorder-table"
    />
  );
};

export default ReordersTable;
