import {
  PurchaseOrderStatus,
  Reup_Product,
  ReupPurchaseOrder,
} from '@/graphql/types';

export type PurchaseOrder = {
  Id: string;
  PoNumber?: string;
  BuyerId: number;
  DisplayPoNumber?: string;
  PurchaseOrderStatus: PurchaseOrderStatus;
  LineItems: LineItem[];
  DateCreated: Date;
  DateUpdated: Date;
  Note?: string;
  SupplierName: string;
  SupplierLicenseNumber?: string;
  SupplierId: string;
  TotalPrice: number;
  OrganizationKey: string;
};

export type LineItem = {
  Id: string;
  PurchaseOrderId: string;
  Product?: Reup_Product;
  RequestedQuantity: number;
  ReceivedQuantity?: number;
  TotalPrice: number;
  UnitPrice?: number;
  OrderSessionId?: string;
  QuantityOnHand?: number;
  IsDeleted: boolean;
};

export type UiProduct = {
  key: string;
  productName: string;
  category: string;
  supplier: string;
  cost: number;
  QuantityOnHand: number;
  QuantityOnOrder: number;
};

// ToDo: This will be removed
export type Product = {
  Id: string;
  Name: string;
  Category: string;
  SupplierId: string;
  SupplierName: string;
  Cost: number;
  QuantityOnHand: number;
  QuantityOnOrder: number;
};
export enum ReorderAssistantState {
  Suggest = 1,
  Order,
}

export interface Reorder {
  key?: string;
  productName?: string;
  quantityOnHand?: number;
  quantityOrdered?: number;
  estimatedStockOut?: string;
  estimatedDailyLoss?: number;
  category: string;
  cost: number;
  supplier: string;
  supplierId: string;
  required: number;
  suggested: number;
  stockOutDate: Date;
  quantity: number;
  unitPrice?: number;
  dailyVelocity: number;
}

export type CsvExport = {
  PoNumber?: string;
  ProductName?: string;
  Organization: string;
  StoreName?: string;
  LicenseNumber: string;
  SupplierName?: string;
  Note?: string;
  PurchaseOrderTotal?: string;
  Quantity?: string;
  LineItemTotal?: number;
  LineItemCost?: number;
};

export type HandleCheckedChangedCallback = (checked: React.Key[]) => void;
export type HandleAutocompleteSelectCallback = (value: string) => void;
export type HandleReorderDataCallback = (value: Reorder[]) => void;
export type HandleReorderProductSearchCallback = (value: string) => void;
export type HandelOnDraftOrdersCreatedCallback = (
  value?: ReupPurchaseOrder[]
) => void;
