import { Input } from 'antd';
import { FC, useState } from 'react';

import { Reup_Product } from '@/graphql/types';
import {
  HandleAutocompleteSelectCallback,
  HandleReorderProductSearchCallback,
  ReorderAssistantState,
} from '@/types';

type AutoCompleteProductProps = {
  products: Reup_Product[];
  reorderAssistantState: ReorderAssistantState;
  onAutoCompleteSelect: HandleAutocompleteSelectCallback;
  onSearch: HandleReorderProductSearchCallback;
};

const { Search } = Input;

const AutoCompleteProduct: FC<AutoCompleteProductProps> = ({
  products,
  reorderAssistantState,
  onAutoCompleteSelect,
  onSearch,
}) => {
  const isDisabled = reorderAssistantState === ReorderAssistantState.Suggest;
  const [selectedValue, setValue] = useState('');

  // MVP - The original intent of the search was to add products not on the Reorder List
  // see https://damafinancial.atlassian.net/browse/REUP-83 for update
  // keeping the commented code here for a future iteration.

  // const onSelect = (value: string) => {
  //   onAutoCompleteSelect(value);
  //   setValue('');
  // };

  return (
    // <AutoComplete
    //   disabled={isDisabled}
    //   style={{ width: '100%', maxWidth: '360px' }}
    //   options={products?.map((p) => ({
    //     value: p?.key,
    //     label: p?.ProductName,
    //   }))}
    //   placeholder="Search all products"
    //   filterOption={(inputValue, option) =>
    //     option?.label
    //       ? option.label.toUpperCase().includes(inputValue.toUpperCase())
    //       : false
    //   }
    //   allowClear
    //   onSelect={onSelect}
    //   onSearch={onSearch}
    //   searchValue={selectedValue}
    //   value={selectedValue}
    // />

    <Search
      placeholder="Search all products"
      onSearch={onSearch}
      style={{ width: '100%', maxWidth: '360px' }}
      disabled={isDisabled}
    />
  );
};

export default AutoCompleteProduct;
