import { FormOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';

import { HandleCheckedChangedCallback, ReorderAssistantState } from '@/types';

type EditButtonProps = {
  reorderAssistantState: ReorderAssistantState;
  onReorderAssistantStateUpdated: (value: ReorderAssistantState) => void;
  onReordersCheckedUpdated: HandleCheckedChangedCallback;
  disabled?: boolean;
};
const EditButton: FC<EditButtonProps> = ({
  reorderAssistantState,
  onReorderAssistantStateUpdated,
  onReordersCheckedUpdated,
  disabled,
}) => {
  if (reorderAssistantState === ReorderAssistantState.Suggest)
    return (
      <Button
        type="primary"
        onClick={() => {
          onReorderAssistantStateUpdated(ReorderAssistantState.Order);
        }}
      >
        Start Ordering
      </Button>
    );
  return (
    <Button
      type="default"
      onClick={() => {
        onReorderAssistantStateUpdated(ReorderAssistantState.Suggest);
        onReordersCheckedUpdated([]);
      }}
      disabled={disabled}
      icon={<FormOutlined />}
    >
      Edit Assistant
    </Button>
  );
};

export default EditButton;
