import { Select } from 'antd';
import React, { FC } from 'react';

const { Option } = Select;

const ReplenishmentStrategy: FC = () => {
  return (
    <div>
      <div>
        <span>Replenishment Strategy</span>
      </div>
      <Select
        disabled
        showSearch
        className="w-full"
        placeholder="Last x Weeks"
        optionFilterProp="children"
      >
        <Option value="lastWeeks">Last x Weeks</Option>
        <Option value="timeSeries">Historical</Option>
      </Select>
    </div>
  );
};

export default ReplenishmentStrategy;
